import React from 'react';
import Descriptor from '../../../Components/Descriptor';
import Image from '../../../Components/Image';

import MISSIONIMAGEpng from '../../../assets/Mission/mission.png';
import MISSIONIMAGEwebp from '../../../assets/Mission/mission.webp';

import styles from './Mission.module.css';

const Mission = () => {
  return (
    <div className={styles.missionContainer} id="mission">
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <Image
            className={styles.image}
            src={MISSIONIMAGEpng}
            webpImage={MISSIONIMAGEwebp}
            alt="mission alienbrains"
          />
        </div>
        <div className={styles.descriptorContainer}>
          <Descriptor
            titleStyle={styles.titleStyle}
            descriptionStyle={styles.descriptionStyle}
            descriptorAlignment={styles.missionTextAlign}
            title="OUR MISSION"
            maxDescriptionCharacters={390}
            description="Our mission is to empower with equality, making sure to make the journey of learning worthwhile. We make sure to lay down a path for every student taking up our course. Alien Brains makes sure in converting a student from a fresher to an expert in which ever technology they choose.
            We at Alien Brains believe that human brain has no boundaries to what it can learn or what it can develop. The entire team of Alien Brains are dedicated towards helping the innovations of the mind become the reality of tomorrow.  We envision ourselves as a support system of all the innovative and creative minds where we help them explore their ideas and thoughts and help them develop products that will have a fruitful impact on the society."
          />
        </div>
      </div>
    </div>
  );
};

export default Mission;
